import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  footerName:any;
  constructor() { }

  ngOnInit() {
    this.footerName="© " + moment(environment.year).format('YYYY') + ' - ' + environment.appName + ' - ' + environment.versionNumber;
  }

}
