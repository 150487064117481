import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class RoleManagementService {

  private apiUrl = environment.apiURL + '/api/userManagement/role';
  constructor(private http: HttpClient) { }

  getRecord(): Observable<any[]> {
    return this.http
      .post<any>(this.apiUrl + '/getRecord', httpOptions)
      .pipe(tap());
  }

  createRecord(data: any): Observable<any[]> {
    return this.http
      .post<any>(this.apiUrl + '/createRecord', data, httpOptions)
      .pipe(tap());
  }

  updateRecord(data: any): Observable<any[]> {
    return this.http
      .post<any>(this.apiUrl + '/updateRecord', data, httpOptions)
      .pipe(tap());
  }

  getRecordByID(data: any): Observable<any[]> {
    return this.http
      .post<any>(this.apiUrl + '/getRecordByID', data, httpOptions)
      .pipe(tap());
  }
}
