import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component'; 
import { ConfirmdeleteComponent } from './pages/master/confirmdelete/confirmdelete.component'; 
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service'; 
import { UserFormComponent } from './admin/user/user-form/user-form.component';
import { UserListComponent } from './admin/user/user-list/user-list.component';
import { UserRoleListComponent } from './admin/role/user-role-list/user-role-list.component';
import { RoleManagementViewComponent } from './admin/role/role-management-view/role-management-view.component';
import { RoleManagementEditComponent } from './admin/role/role-management-edit/role-management-edit.component';
import { PermissionViewComponent } from './admin/permission/permission-view/permission-view.component';
import { ChangePasswordComponent } from './admin/change-password/change-password.component';
import { SettingListComponent } from './../app/pages/master/setting/setting-list/setting-list.component';
import { ChangeProfileImageComponent } from './admin/change-profile-image/change-profile-image.component';
import { SplashscreenComponent } from './pages/splashscreen/splashscreen.component'; 
import { ClientDetailsListComponent } from './pages/master/client-details/client-details-list/client-details-list.component';

const routes: Routes = [ 
   { path: '', component: ClientDetailsListComponent, pathMatch: 'full', canActivate: [AuthGuard] }, 
   { path: 'dashboard', component: DashboardComponent},//, canActivate: [AuthGuard]}, 
   { path: 'login', component: LoginComponent },   
   { path: 'confirmdelete', component: ConfirmdeleteComponent}, 
   { path: 'admin/userForm', component: UserFormComponent },
   { path: 'admin/userList', component: UserListComponent },
   { path: 'admin/permissionView', component: PermissionViewComponent },
   { path: 'admin/roleManagementView', component: RoleManagementViewComponent },
   { path: 'auth/changePassword', component: ChangePasswordComponent },
   { path: 'setting', component: SettingListComponent}, 
   { path: 'auth/changeProfileImage', component: ChangeProfileImageComponent },
   { path: 'splash', component: SplashscreenComponent },
   { path: 'clientdetails', component: ClientDetailsListComponent }
   
   
   
  ];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }), CommonModule],
  exports: [RouterModule],
  declarations: [],
  providers: [AuthService, AuthGuard]
})
export class AppRoutingModule { }
