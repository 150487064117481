import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (environment.mobileApp) {
      if (this.auth.isLoggednIn()) {
        // this.router.navigate(['dashboard']);
        // return false;
        return true;
      } else {
        this.router.navigate(['login']);
        return false;
      }
    } else {
      // this.router.navigate(['login']);
      // return true;

      if (this.auth.isLoggednIn()) {
        return true;
      } else {
        this.router.navigate(['login']);
        return false;
      }

    }
  }
}
