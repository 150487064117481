import { Component, OnInit, ViewChild, Inject, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatPaginator, MatSort, } from '@angular/material';
import { MatTableDataSource, MatBottomSheet, } from '@angular/material';
import { ConfirmdeleteComponent } from './../../confirmdelete/confirmdelete.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../../../auth/auth.service';
import { Subscription } from 'rxjs';
import { ClientDetailsService } from '../client-details.service';
import { ClientDetailsFormComponent } from '../client-details-form/client-details-form.component';

export interface PeriodicElement {
  name: string;
  code: string;
  remarks: string;
}

@Component({
  selector: 'app-client-details-list',
  templateUrl: './client-details-list.component.html',
  styleUrls: ['./client-details-list.component.css']
})
export class ClientDetailsListComponent implements OnInit {
  public isSearchClicked: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isMobileApp;
  isAdminUser: Boolean = false;
  loggedUserDetails: any;
  displayedColumns = [];
  temp = [];
  dataSource = new MatTableDataSource<PeriodicElement>();
  loggedUserDetailSub: Subscription;


  constructor(private router: Router, private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private clientDetailsService: ClientDetailsService,
    private spinner: NgxSpinnerService,
    private auth: AuthService
  ) { }

  ngOnDestroy() {
    this.loggedUserDetailSub.unsubscribe();
  }

  ngOnInit() {
    this.isSearchClicked = false;
    this.getTableData(-1);
    this.loggedUserDetailSub = this.auth.LoggedUserDetail.subscribe(res => {
      if (res && res.data) {
        this.loggedUserDetails = res.data;
      } else if (res) {
        this.loggedUserDetails = res;
      }
      for (let i = 0; i < this.loggedUserDetails.role.length; i++) {
        if (this.loggedUserDetails.role[i].name === 'Admin') {
          this.isAdminUser = true;
        }
      }

      if (this.isAdminUser) {
        this.displayedColumns = [
          'edit',
          'name',
          'code',
          'apiUrl',
          'showRecentList',
          'recentListCount',
          'remarks',
          'delete'
        ];
      } else {
        this.displayedColumns = [
          'name',
          'code',
          'apiUrl',
          'showRecentList',
          'recentListCount',
          'remarks'
        ];
      }
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getTableData(id) {
    this.spinner.show();
    this.clientDetailsService.getClientName(id).subscribe((data: {}) => {
      console.log(data);
      this.spinner.hide();
      this.dataSource.data = data as PeriodicElement[];
    });
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  displaySearch() {
    this.isSearchClicked
      ? (this.isSearchClicked = false)
      : (this.isSearchClicked = true);
    this.getTableData(-1);
  }
  addOrEdit(inputData, isAddButtonClicked) {
    let objTemp;
    objTemp = {
      height: '400px',
      width: '600px',
      disableClose: true,
      hasBackdrop: true,
      data: {}
    }
    if (isAddButtonClicked) {
      objTemp.data = {};
    } else {
      objTemp.data = inputData;
    }
    const dialogRef = this.dialog.open(ClientDetailsFormComponent, objTemp);
    dialogRef.afterClosed().subscribe(result => {
      if (result !== -1) {
        this.ngOnInit();
      }
    });
  }

  deleteTable(item) {
    let bottomSheetRef = this.bottomSheet.open(ConfirmdeleteComponent, {
      data: { id: 2 },
      disableClose: true,
      hasBackdrop: false
    });
    bottomSheetRef.afterDismissed().subscribe(result => {
      if (result === true) {
        const obj = {
          'updatedBy': 'System',
          _id: item._id
        };
        this.clientDetailsService.deleteById(obj).subscribe(res => {
          this.ngOnInit();
        });
      }
    });
  }
}
