import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheet
} from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
// import { ConfirmDeleteComponent } from '../../../master/confirm-delete/confirm-delete.component';
import * as moment from 'moment';

import { UserService } from '../user.service';
import { RoleManagementService } from '../../role/role-management.service';
// import { ClientService } from '../../../master/client/client.service';
// import { VendorService } from '../../../master/vendor/vendor.service';
// import { AlertDialogComponent } from '../../../general/alert-dialog/alert-dialog.component';
import { ConfirmdeleteComponent } from '../../../pages/master/confirmdelete/confirmdelete.component';
import { PermissionService } from '../../permission/permission.service';
import { ShareDataService } from '../../../general/share-data.service';

@Component({
  selector: 'app--user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css']
})

export class UserFormComponent implements OnInit {
  hide = true;
  inputData: any;
  permissionList: any;
  departmentList: any;
  checked = false;
  private obj: any;
  _id: string;
  department: any;
  selectedDepartmentValue;
  chkAllWrite = true; chkAllRead = true; chkAllReadWrite = true;
  role = new FormControl('', [Validators.required]);
  name = new FormControl('', [Validators.required]);
  departmentForm = new FormControl('', [Validators.required]);
  emailId = new FormControl('', [Validators.required, Validators.email]);
  remarks = new FormControl();
  vendor = new FormControl('', [Validators.required]);
  client = new FormControl('', [Validators.required]);
  contactCode = new FormControl('');
  contactNo = new FormControl('');
  roles = []; ROLES = []; CLIENTS = []; VENDORS = [];
  selectedRoles = []; selectedClient = {}; selectedVendor = {};
  selected = ['Admin'];
  color = 'primary';
  clientChecked = false; vendorChecked = false;
  disabled = false;
  clients = []; vendors = []; user: any;
  ModuleList: any;
  permissions = [];  
  permissionData = [];

  constructor(
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private dialogRef: MatDialogRef<UserFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private userService: UserService,
    private roleService: RoleManagementService,
    private permissionService: PermissionService,
    private shareDataService: ShareDataService

    // private clientService: ClientService,
    // private vendorService: VendorService
  ) { }

  ngOnInit() {
    this.getRoles();
    // this.getClients();
    // this.getVendors();
    this.departmentList = []; 
    this.user = this.getFromLocalStorage('userDet');
    if (Object.keys(this.data).length !== 0) {
      this.bindRoles(this.data.role);
      this.name.setValue(this.data.name);
      this.emailId.setValue(this.data.emailId);
      this.role.setValue(this.selected);
      this.remarks.setValue(this.data.remarks);
      // this.clientChecked = this.data.isClient === 'yes' ? true : false;
      // this.vendorChecked = this.data.isVendor === 'yes' ? true : false;
      // this.client.setValue(this.data.client.name);
      // this.vendor.setValue(this.data.vendor.name);
      this.contactCode.setValue(this.data.contactCode);
      this.contactNo.setValue(this.data.contactNo);
      if (this.data.permission) {
        this.permissionList = this.data.permission;
      } else {
        this.loadModuleDetails();
      }
    } else {
      this.loadModuleDetails();
    }
  }

  getRoles = () => {
    this.roleService.getRecord().subscribe(res => {
      this.ROLES = res;
      if (this.ROLES.length > 0) {
        this.ROLES.forEach(element => {
          this.roles.push(element.name);
        });
      }
    });
  }

  
  // checkBoxSelectAll(selectedValue) {
  //   console.log(this.permissionList);
  //   if (selectedValue.checked) {
  //     for (let obj of this.permissionList) {
  //       obj.create = true;
  //       obj.read = true;
  //       obj.write = true;
  //       obj.ref = obj._id;
  //     }
  //   } else {
  //     for (let obj of this.permissionList) {
  //       obj.create = false;
  //       obj.read = false;
  //       obj.write = false;
  //       obj.ref = obj._id;
  //     }
  //   }
  // }
   selectAllReadWrite = () => {
    if (this.permissions) {
      this.permissions.forEach(el => {
        el.write = this.chkAllReadWrite;
        el.read = this.chkAllReadWrite;
      });
      this.chkAllReadWrite = !this.chkAllReadWrite;
    }
    this.showPermissions();
  }

  showPermissions = ()=>{
    let temp = []; 
    let uniqueModules = [];
    this.permissions.forEach(element=>{
      let flag = false;
      if(uniqueModules.length === 0) {
        uniqueModules.push(element.module);
      } else {
        uniqueModules.forEach(elem=>{
          if(elem === element.module) {
            flag = true;
          }
        });
        if(!flag) {
          uniqueModules.push(element.module);
        }
      }
    });

    if(uniqueModules.length > 0) {
      uniqueModules.forEach(elem=>{
        let isFirst = false;
        this.permissions.forEach( (element, index)=>{
          if(elem === element.module) {            
            if(!isFirst) {
              isFirst = true;
              temp.push({
                _id: element._id,
                module: element.module,
                name: '',
                read: '',
                write: '',
                ref: '',
                isActive: element.isActive
             });
             temp.push({
              _id: element._id,
              module: '',
              name: element.name,
              read: element.read,
              write: element.write,
              ref: element.ref,
              isActive: element.isActive
           });
            } else {
              temp.push({
                _id: element._id,
                module: '',
                name: element.name,
                read: element.read,
                write: element.write,
                ref: element.ref,
                isActive: element.isActive
             });
            }            
          }
        });
      });
    }
    this.permissionData = temp;
  }
  selectAllRead = () => {
    if (this.permissions) {
      this.permissions.forEach(el => {
        el.read = this.chkAllRead;
        if (this.chkAllRead) {
          el.write = !this.chkAllRead;
        }
      });
      this.chkAllRead = !this.chkAllRead;
    }
    this.showPermissions();
  }
  selectAllWrite = () => {
    if (this.permissions) {
      this.permissions.forEach(el => {
        el.write = this.chkAllWrite;
        el.read = this.chkAllWrite;
      });
      this.chkAllWrite = !this.chkAllWrite;
    }
    this.showPermissions();
  }
  changeWrite = (id, checked) => {
    if (this.permissions !== undefined && this.permissions.length > 0) {
      this.permissions.forEach(element => {
        if (element._id === id) {
          element.write = !checked;
          if (element.write) {
            element.read = true;
          } else {
            element.read = false;
          }
        }
      });
    }
    this.showPermissions();
  }
  changeRead = (id, checked) => {
    if (this.permissions !== undefined && this.permissions.length > 0) {
      this.permissions.forEach(element => {
        if (element._id === id) {
          element.read = !checked;
          if (element.read) {
            element.write = false;
          }
        }
      });
    }
    this.showPermissions();
  }
  loadModuleDetails() {
    this.permissionList = [];
    this.permissionService.getRecord().subscribe(data => {
      for (let obj of data) {
        obj.create = false;
        obj.read = false;
        obj.write = false;
        obj.ref = obj._id;
      }
      this.permissionList = data;
      console.log(data);
    });

  }

  bindRoles = (roles) => {
    if (roles !== undefined && roles.length > 0) {
      const arr = []; let x;
      x = roles.split(',');
      x.forEach(element => {
        element = element.trim();
        if (element !== undefined) {
          arr.push(element);
        }
      });
      this.selected = arr;
    }
  }

  save(
    name: String,
    emailId: String,
    contactCode: Number,
    contactNo: Number,
    role: String,
    remarks: String,
    client: String,
    vendor: String,
    // Module:string,
    // Read:string,
    // Write:string
    // _id: String
  ): void { 
    if (!name || !emailId || !role) {
      return;
    }
    this.inputData = {
      name: name,
      emailId: emailId,
      contactCode: contactCode,
      contactNo: contactNo,
      role: role,
      remarks: remarks,
      department:
      {
        ref:this.selectedDepartmentValue._id,
       name:this.selectedDepartmentValue.name
      }

      //   Module:Module,
      // Read:false,
      // Write:Write
      //client: client,
      //vendor: vendor
    };
    this.prepareRoles(this.inputData.role);
    this.prepareClient(this.inputData.client);
    this.prepareVendor(this.inputData.vendor);
    this.inputData.role = this.selectedRoles;
    this.inputData.isClient = this.clientChecked;
    this.inputData.isVendor = this.vendorChecked;

    if (this.inputData.isClient) {
      this.inputData.client = this.selectedClient;
    } else {
      this.inputData.client = {
        ref: null,
        name: null
      };
    }

    if (this.inputData.isVendor) {
      this.inputData.vendor = this.selectedVendor;
    } else {
      this.inputData.vendor = {
        ref: null,
        name: null
      };
    }
    this.inputData.permission = this.permissionList;
    if (Object.keys(this.data).length !== 0) {
      this.inputData._id = this.data._id;
      this.inputData.updatedBy = this.user.data.name;
      this.userService.updateRecord(this.inputData).subscribe((res: any) => {

        if (res && res.statusBool) {
          this.dialogRef.close();
        }
        this.shareDataService.openSnackBar(res.message, 'Ok');

      });
    } else {
      this.inputData.createdBy = this.user.data.name;
      this.userService.createRecord(this.inputData).subscribe((res: any) => {

        if (res && res.statusBool) {
          this.dialogRef.close();
        }
        this.shareDataService.openSnackBar(res.message, 'Ok');

      });
    }
  }

  showAlert = (res) => {
    if (!res.statusBool) {
      const dialogRef = this.dialog.open(ConfirmdeleteComponent, {
        data: {
          id: 4,
          title: 'Alert',
          message: res.message
        },
        disableClose: false
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.dialogRef.close();
          this.data = {};
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  prepareRoles = (selectedRoles) => {
    if (this.ROLES !== undefined && this.ROLES.length > 0) {
      if (selectedRoles !== undefined && selectedRoles.length > 0) {
        const arr = [];
        selectedRoles.forEach(element => {
          this.ROLES.forEach(row => {
            if (element === row.name) {
              arr.push({
                'name': row.name,
                'isActive': row.isActive,
                'ref': row._id
              });
            }
          });
        });
        this.selectedRoles = arr;
      }
    }
  }

  btnClose() {
    // console.log(this.permissionList);
    if (
      this.name.dirty ||
      this.emailId.dirty ||
      this.role.dirty ||
      this.remarks.dirty
    ) {
      const bottomSheetRef = this.bottomSheet.open(ConfirmdeleteComponent, {
        data: { id: 2 },
        disableClose: false,
        hasBackdrop: false
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
        if (result === true) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }
  
  getErrorRole() {
    return this.role.hasError('required') ? 'Role is required' : '';
  }

  getErrorClient() {
    return this.client.hasError('required') ? 'Client is required' : '';
  }

  getErrorVendor() {
    return this.vendor.hasError('required') ? 'Vendor is required' : '';
  }

  toggleClient = (checked) => {
    this.clientChecked = (checked) ? false : true;
  }

  toggleVendor = (checked) => {
    this.vendorChecked = (checked) ? false : true;
  }

  getClients = () => {
    // this.clientService.getClientCompany().subscribe(res => {
    //   this.CLIENTS = res;
    //   if (res.length > 0) {
    //     res.forEach(elem => {
    //       if (elem.companyName !== undefined) {
    //         this.clients.push(elem.companyName);
    //       }
    //     });
    //   }
    // });
  }

  getVendors = () => {
    // this.vendorService.getVendorCompany().subscribe(res => {
    //   this.VENDORS = res;
    //   if (res.length > 0) {
    //     res.forEach(elem => {
    //       if (elem.companyName !== undefined) {
    //         this.vendors.push(elem.companyName);
    //       }
    //     });
    //   }
    // });
  }

  prepareClient = (selectedClient) => {
    if (this.CLIENTS.length > 0) {
      this.CLIENTS.forEach(element => {
        if (element.companyName === selectedClient) {
          this.selectedClient = {
            'ref': element._id,
            'name': element.companyName
          };
        }
      });
    }
  }

  prepareVendor = (selectedVendor) => {
    if (this.VENDORS.length > 0) {
      this.VENDORS.forEach(element => {
        if (element.companyName === selectedVendor) {
          this.selectedVendor = {
            'ref': element._id,
            'name': element.companyName
          };
        }
      });
    }
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  displayedColumns: string[] = ['module', 'name', 'remarks', 'read', 'write'];


}
