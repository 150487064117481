import { Component, OnInit, NgZone } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatRadioButton, MatRadioChange } from '@angular/material/radio';
import { Router, ActivatedRoute } from '@angular/router';
import { fade } from './login.animations';
import { AuthService } from '../auth.service';
import { environment } from '../../../environments/environment';
import { ShareDataService } from '../../general/share-data.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { debug } from 'util'; 
import { map, startWith } from 'rxjs/operators';
import { isMoment } from 'moment'; 
import { MatDialog } from '@angular/material'; 
import { GlobalDataService } from '../../general/global-data.service';
import { RoleManagementService } from '../../admin/role/role-management.service';
import { UserService } from 'src/app/admin/user/user.service'; 

declare var FCMPlugin;
declare var window: any;
declare var navigator: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [
    fade
  ]
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  myForm: FormGroup;
  start = new FormControl('', [Validators.required]);

  userName = new FormControl('', [Validators.required]);
  private data = new BehaviorSubject('');
  currentData = this.data.asObservable();
  private formSubmitAttempt: boolean;
  showSpinner: Boolean = false;
  isError: Boolean = false;
  errMsg: String;
  versionNumber: String = environment.versionNumber;
  appName: String = environment.appName;
  mobileApp: Boolean = environment.mobileApp;
  year;
  isLoginSuccess: Boolean = false;

  showDriverDetails: boolean;
  showSupervisorDetails: boolean;

  favoriteSeason: string;
  roleList: string[] = ['Driver', 'Supervisor'];

  profileImage = 'assets/images/no-image.png';
  filteredOptions_start: Observable<any>;

  ProfilePhoto: any;
  vehicleTypeList: any;
  vehicleNameList: any;
  departmentList: any;
  locationList: any;
  roleManagementList: any;
  departmentForm = new FormControl('');
  locationForm = new FormControl('');
  vehicleNameForm = new FormControl('');
  vehicleTypesForm = new FormControl('');
  alterPhone = new FormControl('');
  selectedLocationValue;
  selectedVehicleTypeValue;
  selectedDepartmentValue;
  selectedVehicleNameValue;
  selectedRoleValue;
  tempSelectedRole: any;
  loggedUserId: String;
  loggedDetails: any;
  public notifications: BehaviorSubject<Object> = new BehaviorSubject(false);
  public departmentSelected;
  onDeviceReady: Promise<any>;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private routeTest: ActivatedRoute,
    private dataService: ShareDataService, 
    private authService: AuthService, 
    public dialog: MatDialog, 
    private ngZone: NgZone, 
    private globalData: GlobalDataService,
    private roleManagementService: RoleManagementService,
    private userService: UserService, 
  ) {

  }

  getClassByValue() {
    var value = environment.clientName;
    console.log(value);
    switch (value) {
      case "CUTECH": return "full-cutech";
      case "BRI": return "full-bri";
    }
  }

  ngOnInit() {

    if (environment.isDocker=== true) {
      this.profileImage = 'images/no-image.png';
    }     

    //this.authService.logout();
    console.log(this.dataService.serviceData);
    this.showSupervisorDetails = false;
    this.showDriverDetails = false;
    this.form = this.fb.group({
      emailId: ['', Validators.required],
      password: ['', Validators.required],
      versionNumber: this.versionNumber,
      mobileApp: this.mobileApp
    });

    this.myForm = this.fb.group({
      roleOption: [''],
      userName: '',
      // odometer: '',
      vehicleType: '',
      vehicleNumber: ''
    });
  }

  isFieldInvalid(field: string) {
    if (this.form.get(field) !== undefined && this.form.get(field) !== null) {
      return (
        (!this.form.get(field).valid && this.form.get(field).touched) ||
        (this.form.get(field).untouched && this.formSubmitAttempt)
      );
    }
  }

  onSubmit() {
    console.log(environment);
    this.isError = false;
    this.loggedDetails = {};
    if (this.form.value && this.form.value.emailId && this.form.value.emailId !== '' && this.form.value.password && this.form.value.password !== '') {

    } else {
      return;
    }
    this.authService.login(this.form.value, (res) => {
      console.log('Login Status!!!!!!');
      console.log(res);
      console.log('Login Status!!!!!!');

      if (res && res.statusBool) { 
        this.router.navigate(['/clientdetails']);
      } else {
        let inputLoginHistory = {
          name: this.form.value.emailId,
          attemptTime: new Date(),
          attemptStatus: 'Login',
          attemptResult: false,
          attemptDescription: res,
          userType: null,
          vehicleType: null,
          vehicleName: null,
          location: null,
          department: null,
          alterPhoneNo: null
        }
        this.createLoginHistory(inputLoginHistory);
        this.isLoginSuccess = false;
        this.isError = true;
        this.errMsg = res;
      }
    });
  } 

  navToDashboard(inputLoginHistory) {
    this.createLoginHistory(inputLoginHistory);
    this.router.navigate(['/clientdetails']);
  }

  createLoginHistory(data) {
    this.authService.createLoginHistory(this.loggedDetails, data).subscribe((data_res: any) => {
    });
  }

  showToast(message) {
    this.dataService.openSnackBar(message, 'Ok');
  }  
}



