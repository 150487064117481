import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatBadgeModule } from '@angular/material/badge';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import {
  MatToolbarModule,
  MatTooltipModule,
  MatSidenavModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatMenuModule,
  MatListModule,
  MatExpansionModule,
  MatCardModule,
  MatRadioModule,
  MatSelectModule,
  MatInputModule,
  MatButtonModule,
  MatTableModule,
  MatIconModule,
  MatPaginatorModule,
  MatDialogModule,
  MatBottomSheetModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSortModule,
  MatSnackBarModule,
  MatAutocompleteModule
} from '@angular/material';

import { UserFormComponent } from './admin/user/user-form/user-form.component';
import { UserListComponent } from './admin/user/user-list/user-list.component';
import { PermissionViewComponent } from './admin/permission/permission-view/permission-view.component';
import { RoleManagementViewComponent } from './admin/role/role-management-view/role-management-view.component';
import { RoleManagementEditComponent } from './admin/role/role-management-edit/role-management-edit.component';
import { UserRoleListComponent } from './admin/role/user-role-list/user-role-list.component';
import { UserPermissionListComponent } from './admin/permission/user-permission-list/user-permission-list.component';
import { PermissionUserAndRolelistComponent } from './admin/permission/permission-user-and-rolelist/permission-user-and-rolelist.component';
import { ChangePasswordComponent } from './admin/change-password/change-password.component'

import { LoginComponent } from './auth/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HttpClientModule } from '@angular/common/http'; 
import { ConfirmdeleteComponent } from './pages/master/confirmdelete/confirmdelete.component';
import { NgxSpinnerModule } from 'ngx-spinner';

import { HeaderComponent } from './app-navigation/header/header.component';
import { SidenavListComponent } from './app-navigation/sidenav-list/sidenav-list.component';
import { LayoutComponent } from './layout/layout.component';

import { GlobalDataService } from './general/global-data.service';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { FooterComponent } from './app-navigation/footer/footer.component';
import { SettingListComponent } from './../app/pages/master/setting/setting-list/setting-list.component';
import { SettingFormComponent } from './../app/pages/master/setting/setting-form/setting-form.component';
import { ChangeProfileImageComponent } from './admin/change-profile-image/change-profile-image.component';

import { IonicModule } from '@ionic/angular';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { Media, MediaObject } from '@ionic-native/media/ngx';
import { StreamingMedia, StreamingVideoOptions } from '@ionic-native/streaming-media/ngx';
import { SplashscreenComponent } from './pages/splashscreen/splashscreen.component'; 
import { ClientDetailsFormComponent } from './pages/master/client-details/client-details-form/client-details-form.component';
import { ClientDetailsListComponent } from './pages/master/client-details/client-details-list/client-details-list.component';

@NgModule({
  declarations: [
    AppComponent,
    UserFormComponent, 
    UserListComponent,
    PermissionViewComponent,
    RoleManagementViewComponent,
    UserRoleListComponent,
    UserPermissionListComponent,
    PermissionUserAndRolelistComponent,
    ChangePasswordComponent,
    LoginComponent, 
    DashboardComponent,  
    ConfirmdeleteComponent, 
    HeaderComponent,
    SidenavListComponent,
    LayoutComponent, 
    RoleManagementEditComponent, 
    FooterComponent,
    SettingListComponent,
    SettingFormComponent,
    ChangeProfileImageComponent,
    SplashscreenComponent,  
    ClientDetailsFormComponent,
    ClientDetailsListComponent


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSidenavModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatMenuModule,
    MatListModule,
    MatExpansionModule,
    MatCardModule,
    MatRadioModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    HttpClientModule,
    MatPaginatorModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSortModule,
    MatPaginatorModule,
    NgxSpinnerModule,
    MatSnackBarModule,
    MatBadgeModule,
    NgxDaterangepickerMd.forRoot(),
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatTabsModule,
    Ng2SearchPipeModule,
    AngularFontAwesomeModule
  ],
  entryComponents: [
    RoleManagementEditComponent, PermissionUserAndRolelistComponent, SettingFormComponent, ClientDetailsFormComponent
  ],


  providers: [
    StreamingMedia,
    Media,
    FileTransfer,
    File,
    GlobalDataService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
