import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl = environment.apiURL + '/api/admin/userManagement';
  constructor(private http: HttpClient) { }

  getRecord(): Observable<any[]> {
    return this.http
      .post<any>(this.apiUrl + '/getRecord', httpOptions)
      .pipe(tap());
  }

  getAllUsersCurrentLocation(): Observable<any[]> {
    return this.http
      .post<any>(this.apiUrl + '/getAllUsersCurrentLocation', httpOptions)
      .pipe(tap());
  } 

  createRecord(data: any): Observable<any[]> {
    return this.http
      .post<any>(this.apiUrl + '/createRecord', data, httpOptions)
      .pipe(tap());
  }

  updateRecord(data: any): Observable<any[]> {
    return this.http
      .post<any>(this.apiUrl + '/updateRecord', data, httpOptions)
      .pipe(tap());
  }

  updateVehicleDetails(data: any): Observable<any[]> {
    return this.http
      .post<any>(this.apiUrl + '/updateVehicleDetails', data, httpOptions)
      .pipe(tap());
  }

  deleteRecord(data: any): Observable<any[]> {
    return this.http
      .post<any>(this.apiUrl + '/deleteRecord', data, httpOptions)
      .pipe(tap());
  }

  resetPassword(data: any): Observable<any[]> {
    return this.http
      .post<any>(this.apiUrl + '/resetPassword', data, httpOptions)
      .pipe(tap());
  }
  getLoginProfilePhoto(obj): Observable<any> { 
    return this.http.post<any>(this.apiUrl + '/getLoginProfilePhoto', obj, httpOptions).pipe(
      tap((product) => console.log('')),
      catchError(this.handleError<any>('getLoginProfilePhoto'))
    );
  }

  getUserByEmailId(data: any): Observable<any[]> {
    return this.http
      .post<any>(this.apiUrl + '/getUserByEmailId', data, httpOptions)
      .pipe(tap());
  }

  updateLock(data: any): Observable<any[]> {
    return this.http
      .post<any>(this.apiUrl + '/lockRecord', data, httpOptions)
      .pipe(tap());
  }

  getUsersByVendorId(data: any): Observable<any[]> {
    return this.http
      .post<any>(this.apiUrl + '/getUsersByVendorId', data, httpOptions)
      .pipe(tap());
  } 

  getRecordById(id): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/getRecordById/' + id).pipe(
      map(
        this.extractData)
      );
  }

  deleteDeviceToken(data: any): Observable<any[]> {
    return this.http
      .post<any>(this.apiUrl + '/deleteDeviceToken', data, httpOptions)
      .pipe(tap());
  }


  public extractData(res: Response) {
    let body = res;
    return body || { };
  } 
  getModuleList(data){

  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


}
