import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';

declare var FCMPlugin;
declare var window: any;

export interface DataModel {
  letter: string;
  frequency: number;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  onDeviceReady: Promise<any>;
  loggedUserDetailSub: Subscription;
  loggedUserDetails: any;


  constructor(
    private authService: AuthService,
  ) {
  }

  ngOnDestroy() {
    this.loggedUserDetailSub.unsubscribe();
  }

  ngOnInit() {
    this.loggedUserDetailSub = this.authService.LoggedUserDetail.subscribe(res => {
      if (res && res.data && res.data.loggedUserEntryDetails) {
        this.loggedUserDetails = res.data;
      } else if (res && res.loggedUserEntryDetails) {
        this.loggedUserDetails = res;
      }
    });
  }

}