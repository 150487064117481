import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheet
} from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { UserService } from '../../user/user.service';
import { RoleManagementService } from '../../role/role-management.service';
import { PermissionService } from '../permission.service';
import { ShareDataService } from '../../../general/share-data.service';


@Component({
  selector: 'app-permission-user-and-rolelist',
  templateUrl: './permission-user-and-rolelist.component.html',
  styleUrls: ['./permission-user-and-rolelist.component.css']
})
 
export class PermissionUserAndRolelistComponent implements OnInit {
  user: any; selectedPermission: any; userData: any; roleData: any;
  name = new FormControl('', [Validators.required]); 
  description = new FormControl('');
  module = [
    {value: 'Master', viewValue: 'Master'},
    {value: 'Cute Task', viewValue: 'Cute Task'},
    {value: 'User Management', viewValue: 'User Management'}
  ];
    isAddNew: boolean = false;
    private obj: any;
    _id: string;
    selectedModuleName;
    value: string;
    viewValue: string;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<PermissionUserAndRolelistComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private UserService: UserService,
    private roleService: RoleManagementService,
    private permissionService: PermissionService,
    private shareDataService: ShareDataService,
    @Inject(MAT_DIALOG_DATA) private permissionData: any
    ) { }
  

  ngOnInit() {

    if (Object.keys(this.permissionData).length !== 0) {
  
      this.name.setValue(this.permissionData.name);
        // this.description.setValue(this.permissionData.remarks);
        this._id = this.permissionData._id;
      } else {
        this.isAddNew = true;
      }
    this.user = this.getFromLocalStorage('userDet');
    if (Object.keys(this.data).length !== 0) {
      this.selectedPermission = this.data;
      console.log('-------------this.selectedPermission ');
      console.log(this.selectedPermission);
    }
    this.getAllUsers();   
    this.getAllRoles(); 
  }
  selectedModule(data){
   this. selectedModuleName=data.value;
    // console.log(data);
  }
  getAllUsers=()=>{
    
    this.UserService.getRecord().subscribe(res=>{
      if(!res){
        return
      }
      this.userData = res;
      console.log('-------------this.userData ');
      console.log(this.userData);
      /*
      if(this.userData.length > 0) { 
        let uniqueRoles = [];
        this.userData.forEach(element => {
          if(element.role !== undefined && element.role.length > 0) {            
            element.role.forEach(elem=>{
              let flag = false;
              if(uniqueRoles.length === 0) {
                uniqueRoles.push(elem.name);
              } else {
                uniqueRoles.forEach(ele=>{
                  if(ele === elem.name) {
                    flag = true;
                  }
                });
                if(!flag) {
                  uniqueRoles.push(elem.name);
                }
              }
            });            
          }
        });
        console.log('----------------------uniqueRoles');
        console.log(uniqueRoles);
      }
      */
    });
  }

  getAllRoles=()=>{
    
    this.roleService.getRecord().subscribe(res=>{
      
      if(!res){
        return
      }
      console.log(res);
      this.roleData = res;
      console.log('-------------this.roleData ');
      console.log(this.roleData);
      this.showUserRolelist();
    });
  }

  showUserRolelist=()=>{
    if(this.userData.length>0 && this.roleData.length>0) {
      this.userData.forEach(element=>{
        if(element.role !== undefined && element.role.length > 0) {
          element.role.forEach(userRoleElem=>{
            this.roleData.forEach(uniqueRoleEle=>{
              if(uniqueRoleEle._id === userRoleElem.ref) {
                if(uniqueRoleEle.permission !== undefined && uniqueRoleEle.permission.length > 0) {
                  uniqueRoleEle.permission.forEach(permElement=>{
                   // if(permElement)
                  }); 
                }
              }
            });
          });
        }
      });
    }
  }
  
 
  save(name: String,description: String){
    this.obj = {
      'name': name,
      'description': description,
      'module': this.selectedModuleName
    };

    if (this.isAddNew) {
      this.obj.createdBy = 'System';
      this.permissionService.addPermission(this.obj).subscribe((result) => {
        
        if (result && result.statusBool) {
          this.shareDataService.openSnackBar(result.message, 'Ok');
          this.dialogRef.close();
        }
      }, (err) => {
        console.log(err);
      });
    } else {
      this.obj.updatedBy = 'System';
      this.obj._id = this._id;
      this.permissionService.updatePermission(this.obj).subscribe((result) => {
        if (result && result.statusBool) {
          this.shareDataService.openSnackBar(result.message, 'Ok');
          this.dialogRef.close(1);
        }
      }, (err) => {
        console.log(err);
      });
    }
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }
  btnClose() {
    this.dialogRef.close();
  }
  getErrorBinNumber() {
    return this.name.hasError('required')
      ? 'name is required'
      : this.name.hasError('Duplicate')
        ? 'name Already exists'
        : '';
  }
}
