import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
  
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService implements OnInit {
  user: any;

  private apiUrl = environment.apiURL + '/api/admin/userManagement';
  constructor(private http: HttpClient) { }

  ngOnInit() {

  }


  changePassword(data: any): Observable<any[]> {
    // const emailId = this.authService.LoggedUserDetail.su

    return this.http
      .post<any>(this.apiUrl + '/changePassword', data, httpOptions)
      .pipe(tap(),
        catchError(this.handleError<any>('addHero'))
      );
  }

  changeProfileImage(data: any): Observable<any[]> {
    // const emailId = this.authService.LoggedUserDetail.su
    return this.http
      .post<any>(this.apiUrl + '/changeProfileImage', data, httpOptions)
      .pipe(tap(),
        catchError(this.handleError<any>('addHero'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      error.error.statusBool = false;
      // Let the app keep running by returning an empty result.
      return of(error.error as T);
    };
  }


}
