import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChangePasswordService } from './change-password.service';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { ShareDataService } from '../../general/share-data.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  user: any;
  form: FormGroup;
  isError: Boolean = false;
  errMsg: String = '';


  private formSubmitAttempt: boolean;
  constructor(
    private fb: FormBuilder,
    private changePasswordService: ChangePasswordService,
    private authService: AuthService,
    private router: Router,
    private dataService: ShareDataService,
  ) {
    
  }

  ngOnInit() {

    this.form = this.fb.group({
      password: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      emailId: ['']
    });

    this.authService.getCacheData(-1, this, function (self, res) {
      self.form.setValue({
        password: '',
        newPassword: '',
        confirmPassword: '',
        emailId: res.data.emailId
      });
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  onSubmit() {
    console.log(this.form.value);

    // let obj = {
    //   password: this.form.value.password,
    //   newPassword: this.form.value.newPassword,
    //   confirmPassword: this.form.value.confirmPassword,
    //   emailId: res.data.emailId
    // }

    this.changePasswordService.changePassword(this.form.value).subscribe(res => {
      const resu: any = res;

      this.isError = false;
      this.errMsg = '';

      if (resu.statusBool) {
        this.dataService.openSnackBar(resu.message, 'Ok');
        this.router.navigate(['/dashboard']);
      } else {
        this.isError = true;
        this.errMsg = resu.message;
      }


    });
    this.formSubmitAttempt = true;
  }

}
