import { Component, OnInit ,Inject} from '@angular/core';
import { MatDialog,  MAT_DIALOG_DATA} from '@angular/material';
import { MatTableDataSource,  MatDialogRef} from '@angular/material';
import {FormControl,Validators } from '@angular/forms';

import { SettingService } from './../../../master/setting/setting.service';
import { ShareDataService } from '../../../../general/share-data.service';

@Component({
  selector: 'app-setting-form',
  templateUrl: './setting-form.component.html',
  styleUrls: ['./setting-form.component.css']
})
export class SettingFormComponent implements OnInit {
  key = new FormControl('', [Validators.required]);
  value = new FormControl('');
  remarks = new FormControl('');
  isAddNew: boolean = false;
  private obj: any;
  _id: string;

  constructor(private dialogref: MatDialog, 
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<SettingFormComponent>,
    private settingService: SettingService,
    private shareDataService: ShareDataService,
   
    @Inject(MAT_DIALOG_DATA) private settingData: any
    ) { }
    ngOnInit() {
      if (Object.keys(this.settingData).length !== 0) {
  
      this.key.setValue(this.settingData.key);
      this.value.setValue(this.settingData.value);
        this.remarks.setValue(this.settingData.remarks);
        this._id = this.settingData._id;
      } else {
        this.isAddNew = true;
      }
    }
    showToast(message) {
      this.shareDataService.openSnackBar(message, 'Ok');
    }
    save(key: String,value:string,remarks: String){
      this.obj = {
        'key': key,
        'value': value,
        'remarks': remarks
      };
     
      if (this.value.value === undefined || this.value.value === null || this.value.value === '') {
        this.showToast('Option Value Required');
        return;
      }
      if (this.key.value === undefined || this.key.value === null || this.key.value === '') {
        this.showToast('Option Required');
        return;
      }
     
     
      if (this.isAddNew) {
        this.obj.createdBy = 'System';
        this.settingService.addSetting(this.obj).subscribe((result) => {
         
          // if (this.value.value === undefined || this.value.value === null || this.value.value === '') {
          //   this.showToast('Enter Option Value before Submit');
          //   return;
          // }
          // if (this.key.value === undefined || this.key.value === null || this.key.value === '') {
          //   this.showToast('Enter Option before Submit');
          //   return;
          // }
          if (result && result.statusBool) {
            this.dialogRef.close();
          } 
          this.shareDataService.openSnackBar(result.message, 'Ok');
        }, (err) => {
          console.log(err);
        });
      } else {
        this.obj.updatedBy = 'System';
        this.obj._id = this._id;
        this.settingService.updateSetting(this.obj).subscribe((result) => {
          if (result && result.statusBool) {
            this.shareDataService.openSnackBar(result.message, 'Ok');
            this.dialogRef.close(1);
          }
        }, (err) => {
          console.log(err);
        });
      }
    }
  
    btnClose() {
      this.dialogRef.close();
    }
  
      getErrorBinNumber() {
      return this.key.hasError('required')
        ? 'option is required'
        : this.key.hasError('Duplicate')
          ? 'option Already exists'
          : '';
    }
}
