import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of  } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { catchError, map} from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private apiUrl = environment.apiURL + '/api/userManagement/permission';
  constructor(private http: HttpClient) { }
  public extractData(res: Response) {
    let body = res;
    return body || { };
  } 
  getRecord(): Observable<any[]> {
    return this.http
      .post<any>(this.apiUrl + '/getRecord', httpOptions)
      .pipe(tap());
  }
  getPermission(id): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/getRecords/' + id).pipe(
      map(
        this.extractData)
      );
  }
  addPermission(obj): Observable<any> {
    console.log(obj);
    return this.http.post<any>(this.apiUrl + '/create', obj, httpOptions).pipe(
      tap((product) => console.log(`added Permission w/ id=${product.id}`)),
      catchError(this.handleError<any>('addPermission'))
    );
  }
  updatePermission(obj): Observable<any> {
    console.log(obj);
    return this.http.post<any>(this.apiUrl + '/update', obj, httpOptions).pipe(
      tap((product) => console.log(`added Permission w/ id=${product.id}`)),
      catchError(this.handleError<any>('addPermission'))
    );
  }
  deleteById(obj): Observable<any> {
    console.log(obj);
    return this.http.post<any>(this.apiUrl + '/delete', obj, httpOptions).pipe(
      tap((product) => console.log(`added Permission w/ id=${product.id}`)),
      catchError(this.handleError<any>('addPermission'))
    );
  }
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}


