import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { UserService } from '../../admin/user/user.service';
import { AppNavigationService } from '../app-navigation.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Output() public sidenavToggle = new EventEmitter();
  appName: String = environment.appName;
  isLoggedIn$: Observable<boolean>;
  userName: String;
  loggedUserDetailSub: Subscription;
  loggedUserDetails;

  constructor(
    private auth: AuthService,
    private userService: UserService,
    public nav: AppNavigationService
  ) { }

  ngOnDestroy() {
    this.loggedUserDetailSub.unsubscribe();
  }

  ngOnInit() {
    this.isLoggedIn$ = this.auth.isUserLoggedIn;
    this.loggedUserDetailSub = this.auth.LoggedUserDetail.subscribe(res => {

      if (res && res.data) {
        this.loggedUserDetails = res.data;
      } else if (res && res.loggedUserEntryDetails) {
        this.loggedUserDetails = res;
      }


      if (res && res.data) {
        this.userName = res.data.userId;
        this.auth.showHideSideMenu(res.data);
      }

      if (this.loggedUserDetails && this.loggedUserDetails.userId && this.loggedUserDetails.userId !== null && this.loggedUserDetails.userId !== '') {
        //this.auth.showHideSideMenu(this.loggedUserDetails);
        this.nav.show();
      } else {
        this.nav.hide();
      }
    });
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  public logout = () => {
    this.loggedUserDetails = undefined;
    this.nav.hide();
    this.auth.logout();
    // this.userService.deleteDeviceToken(this.loggedUserDetails).subscribe(results => { 
    //   this.auth.logout();
    //   this.sidenavToggle.emit();
    // });
  }

}
