import { Component, OnInit,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog ,  MatPaginator,  MatSort} from '@angular/material';
import { MatTableDataSource,MatBottomSheet } from '@angular/material';
import { SettingFormComponent }from './../../../master/setting/setting-form/setting-form.component';
import { ConfirmdeleteComponent } from './../../confirmdelete/confirmdelete.component';
import { SettingService } from './../../../master/setting/setting.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShareDataService } from '../../../../general/share-data.service';

export interface PeriodicElement {
  key: string;
  value:string;
  remarks: string;
}

@Component({
  selector: 'app-setting-list',
  templateUrl: './setting-list.component.html',
  styleUrls: ['./setting-list.component.css']
})

export class SettingListComponent implements OnInit {
  public isSearchClicked: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isMobileApp;
  key;
  value;
  remarks;
  displayedColumns = [
    'edit',
    'key',
    'value',
    'remarks',
    'delete'
  ];
  temp = [];
  dataSource = new MatTableDataSource<PeriodicElement>();
  constructor( private router: Router,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private settingService: SettingService,
    private shareDataService: ShareDataService,
    private spinner: NgxSpinnerService) { }
  ngOnInit() {
    this.isSearchClicked = false;
    this.getTableData(-1);
  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  getTableData(id) {
    this.spinner.show();
    this.settingService.getSetting(id).subscribe((data: {}) => {
      console.log(data);
    this.spinner.hide();
    this.dataSource.data = data as PeriodicElement[];
    });
  }

applyFilter(filterValue: string) {
  filterValue = filterValue.trim();
  filterValue = filterValue.toLowerCase();
  this.dataSource.filter = filterValue;
}

displaySearch() {
  this.isSearchClicked
    ? (this.isSearchClicked = false)
    : (this.isSearchClicked = true);
  this.getTableData(-1);
}
showToast(message) {
  this.shareDataService.openSnackBar(message, 'Ok');
}
addOrEdit(inputData, isAddButtonClicked) {
  let objTemp;
  objTemp = {
    disableClose: true,
    hasBackdrop: true,
    data: {}
  }
  if (isAddButtonClicked) {
    objTemp.data = {};
  }else{
    objTemp.data = inputData;
  }
  
  const dialogRef = this.dialog.open(SettingFormComponent, objTemp);
  dialogRef.afterClosed().subscribe(result => {
    if(result !== -1){
      this.ngOnInit();
    }
    
  });
} 

deleteTable(item) {
  let bottomSheetRef = this.bottomSheet.open(ConfirmdeleteComponent, {
    data: {  id: 2 },
    disableClose: true,
    hasBackdrop: false
  });
  bottomSheetRef.afterDismissed().subscribe(result => {
    if (result === true) {
      const obj = {
        'updatedBy': 'System',
        _id: item._id
      };
      this.settingService.deleteById(obj).subscribe(res => {
        this.ngOnInit();
      });
    }
   });
}

}
