import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MatTableDataSource,
  MatBottomSheet
} from "@angular/material";
import { RoleManagementService } from '../role-management.service';
import { Router, ActivatedRoute } from '@angular/router';  
import { ConfirmdeleteComponent } from '../../../pages/master/confirmdelete/confirmdelete.component';
import { RoleManagementEditComponent } from '../../role/role-management-edit/role-management-edit.component';

@Component({
  selector: 'app-role-management-view',
  templateUrl: './role-management-view.component.html',
  styleUrls: ['./role-management-view.component.css']
})
export class RoleManagementViewComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource; user: any;

  displayedColumns = ["edit", "name", "description",  "createdBy", "createdDate", "updatedBy", "updatedDate", "delete"];
  constructor(
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private RoleManagementService: RoleManagementService,
    private router: Router
  ) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    this.RoleManagementService.getRecord().subscribe(results => {
      if(!results) {
        return
      }

      this.dataSource = new MatTableDataSource(results);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  addOrEdit(inputData, isAddButtonClicked) {
    let objTemp;
    objTemp = {
      disableClose: true,
      hasBackdrop: true,
      data: {}
    }
    if (isAddButtonClicked) {
      objTemp.data = {};
    }else{
      objTemp.data = inputData;
    }
    const dialogRef = this.dialog.open(RoleManagementEditComponent, objTemp);
    dialogRef.afterClosed().subscribe(result => {
      if(result !== -1){
        this.ngOnInit();
      }
    });
  } 

  deleteTable=(item)=>{
    let bottomSheetRef = this.bottomSheet.open(ConfirmdeleteComponent, {
      data: {  id: 2 },
      disableClose: true,
      hasBackdrop: false
    });
    bottomSheetRef.afterDismissed().subscribe(result => {
      if (result === true) {
        item.isActive = false;
        item.updatedBy = this.user.data.name;
        this.RoleManagementService.updateRecord(item).subscribe(res => {
          this.ngOnInit();
        });
      }
      if (result === false) {
        this.ngOnInit();
      }
    });
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

}
