import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import {
  MatBottomSheet,
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef
} from '@angular/material';
import { ShareDataService } from '../../../general/share-data.service';
@Component({
  selector: 'app-confirmdelete',
  templateUrl: './confirmdelete.component.html',
  styleUrls: ['./confirmdelete.component.css']
})
export class ConfirmdeleteComponent implements OnInit {
  deleteItem: boolean;
  private result;
  title: string;
  message: string;
  odometerText: string;
  alertType;
  lockStatus;
  expectStatus; 
  constructor(
    private bottomSheetRef: MatBottomSheetRef,
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: any,
    private shareDataService: ShareDataService,
  ) { }

  ngOnInit() { 
    this.alertType = this.data.id;
    if (this.data.id === 1) {
      this.odometerText = "Enter Odometer*"
      this.title = 'Start Trip';
      this.message = 'Confirm ?';
    }
    if (this.data.id === 2) {
      this.title = 'Delete';
      this.message = 'Are you sure want to delete?';
    }
    if (this.data.id === 3) {
      if(this.data.isVehicleTypeExist !== undefined && this.data.isVehicleTypeExist){
        this.title = 'Accept Trip';
        this.message = 'Are you sure want to accept the job?';
      }else{
        this.title = 'Accept Trip';
        this.message = 'Your vehicle type is not matched, Are you sure to accept the job?';  
      }
    }
    if (this.data.id === 4) {
      this.odometerText = "Enter Odometer*"; 
      
      this.title = 'End Trip (Start Odometer Reading : ' + this.data.data.startOdometer + ')';
      this.message = 'Confirm ?';
    }
    if (this.data.id === 5) {
      this.lockStatus = this.data.data.isLocked ? "locked" : "unlocked";
      this.expectStatus = this.data.data.isLocked ? "Unlock" : "Lock";
      this.title = 'Your account is '+ this.lockStatus;
      this.message = 'Do you want to '+ this.expectStatus +'?';
    }
    if (this.data.id === 6) { 
      this.title = 'Add with existing Job';
      this.message = 'Are you sure want to add with existing Job?';
    }

    if (this.data.id === 7) { 
      this.title = 'Exit?';
      this.message = 'Are you sure want to exit?';
    }
    if (this.data.id === 8) { 
      this.title = 'Edit?';
      this.message = 'Are you sure want to edit?';
    }
    if (this.data.id === 9) { 
      this.title = 'Cancel';
      this.message = 'Are you sure want to cancel?';
    }
  }

  btnClose() {
    this.result = false;
    this.bottomSheetRef.dismiss(this.result);
  }

  confirmDelete() {
    if (this.data.id === 1) {
      var odometer = (document.getElementById('odometer') as HTMLInputElement).value;
      if (odometer === undefined || odometer === null || odometer === '') {
        this.shareDataService.openSnackBar('Odometer required', 'Ok');
        return;
      } else {
        this.result = odometer;
      }
    } else if (this.data.id === 4) {
      var odometer = (document.getElementById('odometer') as HTMLInputElement).value;
      if (odometer === undefined || odometer === null || odometer === '') {
        this.shareDataService.openSnackBar('Odometer required', 'Ok');
        return;
      } else {
        if (this.data.data.startOdometer >= parseInt(odometer)) {
          this.shareDataService.openSnackBar('End odometer should greater than start odometer', 'Ok');
          return;
        }
        this.result = odometer;
      }
    } else {
      this.result = true;
    }
    this.bottomSheetRef.dismiss(this.result);
  }
}

