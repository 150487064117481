import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, tap, catchError } from 'rxjs/operators';
import { UserService } from '../admin/user/user.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private userDetail: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private apiUrl = environment.apiURL + '/api/auth';
  private token: string;
  storeLoggedEntery: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  login(user: any, response) {
    this.authenticate(user).subscribe(result => {
      if (result.statusBool) {

        this.insertLocalStorage('userDet', JSON.stringify(result));
        // this.userDetail.next(result);
        // this.loggedIn.next(true);

        //this.navigateForLanding(result);
        response(result);
      } else {
        response(result.attemptDescription);
      }

    });
  }

  insertLocalStorage(key: string, info: any) {
    localStorage.setItem(key, info);
  }

  insertLoggedUserEntryDetailsOnLocalStorage(info: any) {
    this.storeLoggedEntery = JSON.parse(localStorage.getItem('userDet'));
    this.storeLoggedEntery.data.loggedUserEntryDetails = info
    this.insertLocalStorage('userDet', JSON.stringify(this.storeLoggedEntery));
  }

  insertLoggedUserEntryDetailsOnLocalStorageforAutoLogin(info: any) {
    this.storeLoggedEntery = info
    this.insertLocalStorage('userDet', JSON.stringify(this.storeLoggedEntery));
  }

  authenticate(user: any): Observable<any> {
    console.log(this.apiUrl);
    console.log(user);
    return this.http.post<any>(this.apiUrl + '/login', user, httpOptions).pipe(
      tap(),
      catchError(this.handleError<any>('addHero'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      error.error.data.statusBool = false;
      // Let the app keep running by returning an empty result.
      return of(error.error.data as T);
    };
  }

  logout() {
    try {
      var _userdetails = this.getFromLocalStorage('userDet');
      this.userService.deleteDeviceToken(_userdetails.data).subscribe(results => {

      });
    } catch (ex) {
    }

    this.loggedIn.next(false);
    this.userDetail.next({});
    this.removeLocalStorage('userDet');
    this.router.navigate(['/login']);
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  get isUserLoggedIn() { 
    return this.loggedIn.asObservable();
  }

  removeLocalStorage(key: string) {
    localStorage.removeItem(key);
  }

  isLoggednIn() {
    return this.getFromLocalStorage('userDet') !== null;
  }

  get LoggedUserDetail() {
    const user = this.getFromLocalStorage('userDet');
    console.log(user);
    this.userDetail.next(user);
    return this.userDetail.asObservable();
  }

  getCacheData(id, self, res) {
    res(self, this.getFromLocalStorage('userDet'));
  }

  // createLoginHistory(loggedResult, obj): Observable<any> {
  //   console.log('11*****************************');
  //   console.log(obj);
  //   this.showHideSideMenu(loggedResult);
  //   var userModuleTemp = environment.apiURL + '/api/admin/userManagement';
  //   return this.http.post<any>(userModuleTemp + '/createLoginHistory', obj, httpOptions).pipe(
  //     tap((product) => console.log(`create history`)),
  //     catchError(this.handleError<any>('createHistory'))
  //   );
  // }

  showHideSideMenu(loggedResult) {
    this.userDetail.next(loggedResult);
    this.loggedIn.next(true);
  }


  updateTokenByUserId(obj): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/updateTokenByUserId', obj, httpOptions).pipe(
      tap((product) => console.log(`added location w/ id=${product.id}`)),
      catchError(this.handleError<any>('createBooking'))
    );
  }

  createLoginHistory(loggedResult, obj): Observable<any> {
    if (obj.attemptStatus === 'Login' && obj.attemptResult) {
      this.showHideSideMenu(loggedResult);
    }
    return this.http.post<any>(environment.apiURL + '/api/admin/userManagement/createLoginHistory', obj, httpOptions).pipe(
      tap((product) => console.log(`create History`)),
      catchError(this.handleError<any>('createBooking'))
    );
  }

}
