import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MatTableDataSource,
  MatBottomSheet
} from "@angular/material";
import { PermissionService } from '../permission.service';
import { PermissionUserAndRolelistComponent } from '../permission-user-and-rolelist/permission-user-and-rolelist.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmdeleteComponent } from '../../../pages/master/confirmdelete/confirmdelete.component';
import { environment } from '../../../../environments/environment';


export interface PeriodicElement {
  name: string;
  remarks: string;
}
@Component({
  selector: 'app-permission-view',
  templateUrl: './permission-view.component.html',
  styleUrls: ['./permission-view.component.css']
})
export class PermissionViewComponent implements OnInit { 
  public isSearchClicked: boolean;

  isMobileApp: Boolean = environment.mobileApp;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource;

  displayedColumns = ["edit","module", "name", "description", "userAndRoles", "delete"];
  
  constructor(
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private PermissionService: PermissionService,
    private spinner: NgxSpinnerService) { }

  

  ngOnInit() {
    this.isSearchClicked = false;
    this.getTableData(-1);
    this.PermissionService.getRecord().subscribe(results => {
      if(!results) {
        return
      }

      this.dataSource = new MatTableDataSource(results);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }
  
displaySearch() {
  this.isSearchClicked
    ? (this.isSearchClicked = false)
    : (this.isSearchClicked = true);
 
}
getTableData(id) {
  this.spinner.show();
  this.PermissionService.getPermission(id).subscribe((data: {}) => {
    console.log(data);
  this.spinner.hide();
  this.dataSource.data = data as PeriodicElement[];
  });
}

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  addOrEdit(inputData, isAddButtonClicked) {
    let objTemp;
    objTemp = {
      disableClose: true,
      hasBackdrop: true,
      data: {}
    }
    if (isAddButtonClicked) {
      objTemp.data = {};
    }else{
      objTemp.data = inputData;
    }
    const dialogRef = this.dialog.open(PermissionUserAndRolelistComponent, objTemp);
    dialogRef.afterClosed().subscribe(result => {
      if(result !== -1){
        this.ngOnInit();
      }
    });
  } 
  getUserAndRoles=(row)=>{
    let dialogRef = this.dialog.open(PermissionUserAndRolelistComponent, {
      data: row,
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }
  deleteTable(item) {
    let bottomSheetRef = this.bottomSheet.open(ConfirmdeleteComponent, {
      data: {  id: 2 },
      disableClose: true,
      hasBackdrop: false
    });
    bottomSheetRef.afterDismissed().subscribe(result => {
      if (result === true) {
        const obj = {
          'updatedBy': 'System',
          _id: item._id
        };
        this.PermissionService.deleteById(obj).subscribe(res => {
          this.ngOnInit();
        });
      }
     });
  }

}
