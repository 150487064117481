import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {

  private currentLocation: any;

  constructor() { }

  setCurrentLocation(val) {
    this.currentLocation = val;
    console.log("Location Updated on Global Variable!!!");
  }

  getCurrentLocation() {
    return this.currentLocation;
  }
}
