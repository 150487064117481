import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { MatTableDataSource, MatDialogRef } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { ShareDataService } from '../../../../general/share-data.service';
import { ClientDetailsService } from '../client-details.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { MatRadioButton, MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-client-details-form',
  templateUrl: './client-details-form.component.html',
  styleUrls: ['./client-details-form.component.css']
})
export class ClientDetailsFormComponent implements OnInit {
  name = new FormControl('', [Validators.required]);
  code = new FormControl('', [Validators.required]);
  apiUrl = new FormControl('', [Validators.required]);
  remarks = new FormControl('');
  isAddNew: boolean = false;
  private obj: any;
  _id: string;
  vehicleTypes: any;
  selectedVehicleTypeValue;
  start: string;
  vehicleTypesForm = new FormControl('');
  isMobileApp;
  loggedUserDetailSub: Subscription;
  loggedUserDetails: any;
  showRecentList: string = 'Yes';
  recentListCount: number = 15;


  constructor(private dialogref: MatDialog,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ClientDetailsFormComponent>,
    private shareDataService: ShareDataService,
    @Inject(MAT_DIALOG_DATA) private clientData: any,
    private clientDetailsService: ClientDetailsService,
    private authService: AuthService
  ) { }

  ngOnDestroy() {
    this.loggedUserDetailSub.unsubscribe();
  }

  ngOnInit() {
    this.loggedUserDetailSub = this.authService.LoggedUserDetail.subscribe(res => {
      if (res && res.data && res.data.loggedUserEntryDetails) {
        this.loggedUserDetails = res.data;
      } else if (res) {
        this.loggedUserDetails = res;
      }
    });

    if (Object.keys(this.clientData).length !== 0) {
      this.name.setValue(this.clientData.name);
      this.code.setValue(this.clientData.code);
      this.apiUrl.setValue(this.clientData.apiUrl);
      this.remarks.setValue(this.clientData.remarks); 
      this._id = this.clientData._id;
      this.recentListCount = this.clientData.recentListCount; 
      this.showRecentList = this.clientData.showRecentList; 
    } else {
      this.isAddNew = true;
    }
  }

  startLocationDD(data) {
  }
  showToast(message) {
    this.shareDataService.openSnackBar(message, 'Ok');
  }
  save(name: String, code: String, remarks: String, apiUrl: String) {

    this.obj = {
      name: name,
      code: code,
      apiUrl: apiUrl,
      remarks: remarks,
      recentListCount: this.recentListCount,
      showRecentList: this.showRecentList,
    };

    if (name === undefined || name === null || name === '') {
      this.showToast('Client Name is Required');
      return;
    }

    if (code === undefined || code === null || code === '') {
      this.showToast('Client Code is Required');
      return;
    } 
    
    if (apiUrl === undefined || apiUrl === null || apiUrl === '') {
      this.showToast("API URL is Required");
      return;
    }

    if(this.recentListCount && this.recentListCount > 15){
      this.showToast("Recent punch list count limit is exceeded!!!");
      return;
    }

  console.log(this.loggedUserDetails);
    if (this.isAddNew) {
      this.obj.createdBy = this.loggedUserDetails.userId;
      this.clientDetailsService.addClientName(this.obj).subscribe((result) => {
        if (result && result.statusBool) {
          this.shareDataService.openSnackBar(result.message, 'Ok');
          this.dialogRef.close();
        }
      }, (err) => {
        console.log(err);
      });
    } else {
      this.obj.updatedBy = this.loggedUserDetails.userId;
      this.obj._id = this._id;
      this.clientDetailsService.updateClientName(this.obj).subscribe((result) => {
        if (result && result.statusBool) {
          this.shareDataService.openSnackBar(result.message, 'Ok');
          this.dialogRef.close(1);
        }
      }, (err) => {
        console.log(err);
      });
    }
  }

  btnClose() {
    this.dialogRef.close(-1);
  }

  getErrorName() {
    return this.name.hasError('required')
      ? 'Name is required'
      : this.name.hasError('Duplicate')
        ? 'Name Already exists'
        : '';
  }

  getErrorCode() {
    return this.code.hasError('required')
      ? 'Code is required'
      : this.code.hasError('Duplicate')
        ? 'Code Already exists'
        : '';
  }

  getErrorApiUrl() {
    return this.apiUrl.hasError('required')
      ? 'API URL is required'
      : this.apiUrl.hasError('Duplicate')
        ? 'API URL Already exists'
        : '';
  } 
}
