import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { ShareDataService } from '../../general/share-data.service';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserService } from '../../admin/user/user.service';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.css']
})
export class SidenavListComponent implements OnInit {
  @Output() sidenavClose = new EventEmitter();
  isDriverRole: boolean;
  isAdminUser: boolean;
  loggedUserDetailSub: Subscription;
  loggedUserDetails;

  constructor(
    private auth: AuthService,
    private dataService: ShareDataService,
    public router: Router,
    private userService: UserService
  ) { }

  ngOnDestroy() {
    this.loggedUserDetailSub.unsubscribe();
  }

  ngOnInit() {
    console.log('Side Navigation******************');
    this.isDriverRole = false;

    this.loggedUserDetailSub = this.auth.LoggedUserDetail.subscribe(res => {
      console.log(res);

      if (res && res.data) {
        this.loggedUserDetails = res.data;
      } else if (res && res.loggedUserEntryDetails) {
        this.loggedUserDetails = res;
      }


      if (res && res.data && res.data.loggedUserEntryDetails) {

        this.isAdminUser = false;
        for (let i = 0; i < res.data.role.length; i++) {
          if (res.data.role[i].name === 'Admin') {
            this.isAdminUser = true;
          }
        }

        if (res.data.loggedUserEntryDetails.selectedRole === "Driver") {
          this.isDriverRole = true;
        } else {
          this.isDriverRole = false;
        }
      }
    });
  }

  public onSidenavClose = (data) => {

    if (data === 1) {
      if (this.isDriverRole) {
        this.router.navigate(['homeDriver']);
      } else {
        this.router.navigate(['homeSupervisor']);
      }
    } else if (data === 2) {
      if (this.isDriverRole) {
        this.router.navigate(['bookingDriver']);
      } else {
        this.router.navigate(['bookingSupervisor']);
      }
    }
    this.sidenavClose.emit();
  }

  public logout = () => {  
    this.userService.deleteDeviceToken(this.loggedUserDetails).subscribe(results => {
      this.auth.logout();
      this.sidenavClose.emit();
    });
  }

}
