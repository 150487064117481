import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment.prod';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalDataService } from '../../general/global-data.service';
import { MatDialog } from '@angular/material';

declare var FCMPlugin;
declare var window: any;
declare var navigator: any;

@Component({
  selector: 'app-splashscreen',
  templateUrl: './splashscreen.component.html',
  styleUrls: ['./splashscreen.component.css']
})
export class SplashscreenComponent implements OnInit {

  isDriverRole: boolean;
  onDeviceReady: Promise<any>;
  loggedUserDetailSub: Subscription;
  loggedUserDetails: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private ngZone: NgZone,
    private globalData: GlobalDataService,
    public dialog: MatDialog,
  ) { }

  ngOnDestroy() {
    this.loggedUserDetailSub.unsubscribe();
  }

  ngOnInit() {

    this.isDriverRole = false;
    this.loggedUserDetailSub = this.authService.LoggedUserDetail.subscribe(res => {
      if (res && res.data && res.data.loggedUserEntryDetails) {
        this.loggedUserDetails = res.data; 
      } else if (res && res.loggedUserEntryDetails) {
        this.loggedUserDetails = res; 
      } 

      this.router.navigate(['clientdetails']);
    });
  } 
}
