import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders(
    { 
      'Content-Type': 'application/json'
    })
}; 
@Injectable({
  providedIn: 'root'
})
export class SettingService {
  private apiUrl = environment.apiURL + '/api/administration/setting';

  constructor(private http: HttpClient) { }

  public extractData(res: Response) {
    let body = res;
    return body || { };
  } 
  getSetting(id): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/getRecords/' + id).pipe(
      map(
        this.extractData)
      );
  }
  addSetting(obj): Observable<any> {
    console.log(obj);
    return this.http.post<any>(this.apiUrl + '/create', obj, httpOptions).pipe(
      tap((product) => console.log(`added setting w/ id=${product.id}`)),
      catchError(this.handleError<any>('addSetting'))
    );
  }
  updateSetting(obj): Observable<any> {
    console.log(obj);
    return this.http.post<any>(this.apiUrl + '/update', obj, httpOptions).pipe(
      tap((product) => console.log(`added setting w/ id=${product.id}`)),
      catchError(this.handleError<any>('addSetting'))
    );
  }
  deleteById(obj): Observable<any> {
    console.log(obj);
    return this.http.post<any>(this.apiUrl + '/delete', obj, httpOptions).pipe(
      tap((product) => console.log(`added setting w/ id=${product.id}`)),
      catchError(this.handleError<any>('addSetting'))
    );
  }
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
