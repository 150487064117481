import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MatTableDataSource,
  MatBottomSheet
} from '@angular/material';
import { UserFormComponent } from '../user-form/user-form.component';
import { UserService } from '../user.service';
// import { LockConfigComponent } from '../../../general/lock-config/lock-config.component';
import { FormControl, Validators } from "@angular/forms";
// import { ClientService } from '../../../master/client/client.service';
// import { VendorService } from '../../../master/vendor/vendor.service';
// import { AlertDialogComponent } from '../../../general/alert-dialog/alert-dialog.component';
import { ConfirmdeleteComponent } from '../../../pages/master/confirmdelete/confirmdelete.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource;
  user: any; users: any; emailIds: any;
  clientList: any; vendorList: any;
  doFilter: Boolean; filterData: any; RESULTS: any;
  filteredRecords: any;

  seUsername = new FormControl("");
  seEmail = new FormControl("");
  seClient;// = new FormControl("");
  seVendor;// = new FormControl("");


  displayedColumns = ['edit', 'name', 'role','department', 'emailId', 'phoneNo', 'isLocked', 'resetPassword', 'lastLocked',
    'lastPasswordChanged', 'source', 'lastLogin', 'delete'];
  constructor(
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private userService: UserService
    // private ClientService: ClientService,
    // private VendorService: VendorService
  ) { }

  ngOnInit() {
    this.userService.getRecord().subscribe(results => {
      if (!results) {
        return;
      }
      this.RESULTS = results;
      this.bindDataTable(results);
      this.dataSource = new MatTableDataSource(results);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
    this.user = this.getFromLocalStorage('userDet');
  }

  bindDataTable = (results) => {
    let users = []; let emailIds = [];
    if (results !== undefined && results.length > 0) {
      results.forEach(element => {
        const arr = [];
        element.isClient = element.isClient ? "yes" : "";
        element.isVendor = element.isVendor ? "yes" : "";
        if (element.role && element.role !== undefined && element.role.length > 0) {
          let str = "";
          element.role.forEach(el => {
            if (el.isActive) {
              if (str === "") {
                str = el.name + ", ";
              } else {
                str = str + el.name + ", ";
              }
            }
          });
          element.role = str.replace(/,\s*$/, "");;
        }
        if (element.name !== undefined && element.name !== null && element.name.length > 0) {
          users.push(element.name);
        }
        if (element.emailId !== undefined && element.emailId !== null && element.emailId.length > 0) {
          emailIds.push(element.emailId);
        }
        if (element.phoneNo !== undefined && element.phoneNo === '+0-0') {
          element.phoneNo = '';
        }
      });
    }
    this.users = users; //to bind it in filter 
    this.emailIds = emailIds; //to bind it in filter
  }

  btnAddNewClick() {
    let dialogRef = this.dialog.open(UserFormComponent, {
      data: {},
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  getTable(row) {
    let splitPhNo = '';
    if (row.phoneNo === null || row.phoneNo === '' || row.phoneNo === undefined) {
      row.contactCode = undefined;
      row.contactNo = undefined;
    } else {
      splitPhNo = row.phoneNo.split('+');

      if (splitPhNo.length === 2) {
        splitPhNo = splitPhNo[1].toString();
        let seperateCode = splitPhNo.split('-');
        row.contactCode = seperateCode[0].toString();
        row.contactNo = seperateCode[1].toString();
        row.contactCode = Number(row.contactCode);
        row.contactNo = Number(row.contactNo);
      }
    }
    let dialogRef = this.dialog.open(UserFormComponent, {
      data: row,
      disableClose: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  deleteTable(item) {
    let bottomSheetRef = this.bottomSheet.open(ConfirmdeleteComponent, {
      data: { id: 2 },
      disableClose: false,
      hasBackdrop: false
    });
    bottomSheetRef.afterDismissed().subscribe(result => {
      if (result === true) {
        item.isActive = false;
        item.updatedBy = this.user.data.name;
        this.userService.updateRecord(item).subscribe(res => {
          this.ngOnInit();
        });
      }
      if (result === false) {
        this.ngOnInit();
      }
    });
  }

  resetPassword = (item) => {
    // let dialogRef = this.dialog.open(ConfirmDialogComponent, {
    //   data: {},
    //   disableClose: true
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result === true) {
    //     this.userService.resetPassword(item).subscribe(res => {
    //       this.ngOnInit();
    //     });
    //   }
    //   if (result === false) {
    //     this.ngOnInit();
    //   }
    // });
  }

  manageLockAccount = (row) => {
    let bottomSheetRef = this.bottomSheet.open(ConfirmdeleteComponent, {
      data: {
        id: 5,
        data: row
      },
      disableClose: true,
      hasBackdrop: false
    });
    bottomSheetRef.afterDismissed().subscribe(result => {
      if (result === true) {
        row.updatedBy = this.user.data.name;
        this.userService.updateLock(row).subscribe(res => {
          this.ngOnInit();
        });
      }
      if (result === false) {
        this.ngOnInit();
      }
    });
  }

  btnFilter = () => {
    this.doFilter = (this.doFilter) ? false : true;
    this.getClients();
    this.getVendors();
    if (!this.doFilter) {
      this.btnClear();
    }
  }

  getFilterDataByName = (item) => {
    if (this.RESULTS && this.RESULTS.length > 0) {
      let sel_ = [];
      this.RESULTS.forEach(element => {
        if (element.name === item) {
          if (element.emailId !== undefined && element.emailId !== null) {
            this.seEmail.setValue(element.emailId);
          }
          if (element.client.name !== null) {
            this.seClient.setValue(element.client.name);
          } else {
            this.seClient.setValue('');
          }
          if (element.vendor.name !== null) {
            this.seVendor.setValue(element.vendor.name);
          } else {
            this.seVendor.setValue('');
          }
          sel_.push(element);
        }
      });
      this.filteredRecords = sel_;
    }
  }
  getFilterDataByEmailId = (item) => {
    if (this.RESULTS && this.RESULTS.length > 0) {
      let sel_ = [];
      this.RESULTS.forEach(element => {
        if (element.emailId === item) {
          if (element.name !== undefined && element.name !== null) {
            this.seUsername.setValue(element.name);
          }
          if (element.client.name !== null) {
            this.seClient.setValue(element.client.name);
          } else {
            this.seClient.setValue('');
          }
          if (element.vendor.name !== null) {
            this.seVendor.setValue(element.vendor.name);
          } else {
            this.seVendor.setValue('');
          }
          sel_.push(element);
        }
      });
      this.filteredRecords = sel_;
    }
  }
  getFilterDataByClient = (item) => {
    if (this.RESULTS && this.RESULTS.length > 0) {
      let sel_ = [];
      let users = []; let emailIds = []; let vendors = [];
      this.RESULTS.forEach(element => {
        if (element.client.name === item) {
          if (element.name !== undefined && element.name !== null) {
            users.push(element.name);
          }
          if (element.emailId !== undefined && element.emailId !== null) {
            emailIds.push(element.emailId);
          }
          if (element.vendor.name !== undefined && element.vendor.name !== null) {
            vendors.push(element.vendor.name);
          }
          sel_.push(element);
        }
      });
      this.filteredRecords = sel_;
      this.users = users;
      this.emailIds = emailIds;
      this.vendorList = vendors;
    }
  }
  getFilterDataByVendor = (item) => {
    if (this.RESULTS && this.RESULTS.length > 0) {
      let sel_ = [];
      let users = []; let emailIds = []; let clients = [];
      this.RESULTS.forEach(element => {
        if (element.vendor.name === item) {
          if (element.name !== undefined && element.name !== null) {
            users.push(element.name);
          }
          if (element.emailId !== undefined && element.emailId !== null) {
            emailIds.push(element.emailId);
          }
          if (element.client.name !== undefined && element.client.name !== null) {
            clients.push(element.client.name);
          }
          sel_.push(element);
        }
      });
      this.filteredRecords = sel_;
      this.users = users;
      this.emailIds = emailIds;
      this.clientList = clients;
    }
  }


  btnSearch = (name, emailId, client, vendor) => {
    if (name === '' && emailId === '' && client === '' && vendor === '') {
      let res = {
        statusBool: false,
        message: "Select atleast one field"
      }
      let dialogRef = this.dialog.open(ConfirmdeleteComponent, {
        data: {
          id: 4,
          title: 'Alert',
          message: res.message
        },
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();
      });
    } else if (name === '' && emailId === '' && client !== "" && vendor !== "") {
      this.filterFromClientAndVendor(client, vendor);
      this.dataSource = new MatTableDataSource(this.filteredRecords);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    } else {
      this.dataSource = new MatTableDataSource(this.filteredRecords);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  btnClear = () => {
    this.seUsername.setValue('');
    this.seEmail.setValue('');
    this.seClient.setValue('');
    this.seVendor.setValue('');
    this.getClients();
    this.getVendors();
    this.getUsersAndEmailIds();
    this.ngOnInit();
    this.filteredRecords = [];
  }

  filterFromClientAndVendor = (client, vendor) => {
    if (this.RESULTS !== undefined && this.RESULTS.length > 0) {
      let sel_ = []; let users = []; let emailIds = [];
      this.RESULTS.forEach(element => {
        if (element.client.name !== null && element.vendor.name !== undefined) {
          if (element.client.name === client && element.vendor.name === vendor) {
            sel_.push(element);
          }
        }
      });
      this.filteredRecords = sel_;
      this.users = users;
      this.emailIds = emailIds;
    }
  }

  getUsersAndEmailIds = () => {
    if (this.RESULTS !== undefined && this.RESULTS.length > 0) {
      let users = []; let emailIds = [];
      this.RESULTS.forEach(element => {
        if (element.name !== undefined && element.name !== null) {
          users.push(element.name);
        }
        if (element.emailId !== undefined && element.emailId !== null) {
          emailIds.push(element.emailId);
        }
      });
      this.users = users;
      this.emailIds = emailIds;
    }
  }

  getClients = () => {
    // let clients = [];
    // this.ClientService.getClientCompany().subscribe(res => {
    //   if (res.length > 0) {
    //     res.forEach(elem => {
    //       if (elem.companyName !== undefined) {
    //         clients.push(elem.companyName)
    //       }
    //     })
    //   }
    // });
    // this.clientList = clients;
  }

  getVendors = () => {
    // let vendors = [];
    // this.VendorService.getVendorCompany().subscribe(res => {
    //   if (res.length > 0) {
    //     res.forEach(elem => {
    //       if (elem.companyName !== undefined) {
    //         vendors.push(elem.companyName)
    //       }
    //     })
    //   }
    // });
    // this.vendorList = vendors;
  }


  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

}
