import { Injectable } from '@angular/core';import {  
  MatSnackBar
} from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class ShareDataService {
  serviceData: string;
  loggedUserDetails: any
  constructor(private snackBar: MatSnackBar) { }

  openSnackBar(message: string, action: string) { 
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
  
}
