try {
  var app = document.URL.indexOf('http://') === -1 && document.URL.indexOf('https://') === -1;
  var isMobileDevice = false;
  var platform = 'web';
  if (app) {
    isMobileDevice = true;
  }
  if (isMobileDevice) {
    if (/Android/.exec(navigator.userAgent)) {
      platform = 'android';
    } else if (/(iPad)|(iPhone)|(iPod)/.exec(navigator.userAgent)) {
      platform = 'ios';
    } else if (/(BB10)|(PlayBook)|(BlackBerry)/.exec(navigator.userAgent)) {
      platform = 'blackberry';
    }
  }

} catch (ex) {
  console.log(ex);
}

export const environment = { 
  production: true,
  appName: 'cuteGeoPunch', 
  apiURL : '',    
  mobileApp: isMobileDevice,
  versionNumber: '2.0',
  year: new Date(), 
  platform: platform,
  clientName: "CUTECH", //'CUTECH', 'BRI' 
  isDocker: true
};

if(environment.clientName === 'BRI'){
  environment.appName = 'Rathaa';
  environment.apiURL = 'http://cuteqm.com:9030';
}
console.log(environment); 