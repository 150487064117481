import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { ChangePasswordService } from '../change-password/change-password.service';
import { ShareDataService } from '../../general/share-data.service';
// import { BookingService } from '../../pages/booking/booking.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-change-profile-image',
  templateUrl: './change-profile-image.component.html',
  styleUrls: ['./change-profile-image.component.css']
})
export class ChangeProfileImageComponent implements OnInit {
  profileImage = 'assets/images/no-image.png';
  isError: Boolean = false;
  errMsg: String = '';
  profileData: any;
  loggedUserDetailSub: Subscription;
  constructor(
    private authService: AuthService,
    private router: Router,
    private changePasswordService: ChangePasswordService,
    // private bookingService: BookingService,
    private dataService: ShareDataService
  ) { }

  ngOnDestroy() {
    this.loggedUserDetailSub.unsubscribe();
  }

  ngOnInit() {
    if (environment.isDocker=== true) {
      this.profileImage = 'images/no-image.png';
    }
    this.loggedUserDetailSub = this.authService.LoggedUserDetail.subscribe(res => {
      if (res && res.data) {
        this.profileData = res.data;
      } else if (res && res.loggedUserEntryDetails) {
        this.profileData = res;
      }

      if (this.profileData && this.profileData._id) {
        // this.bookingService.getImageByBookingId({ _id: this.profileData._id, module: 'ProfileImage' }).subscribe((result) => {
        //   if (result && result.statusBool && result.data && result.data.base64) {
        //     this.profileImage = "data:image/" + result.data.fileType + ";base64," + result.data.base64;
        //     console.log(result.data)
        //   }
        // }, (err) => {
        //   console.log(err);
        // });
      }


    });
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.profileImage = event.target.result;
    });
    reader.readAsDataURL(file);
  }

  updateProfileImage() { 
    var inputData = {
      _id: this.profileData._id,
      profileImage: this.profileImage,
      createdBy: this.profileData.emailId,
      jobName: 'profileImage'
    }
    this.changePasswordService.changeProfileImage(inputData).subscribe(res => {
      const resu: any = res;

      this.isError = false;
      this.errMsg = '';

      if (resu.statusBool) {
        this.dataService.openSnackBar(resu.message, 'Ok');
        this.router.navigate(['/dashboard']);
      } else {
        this.isError = true;
        this.errMsg = resu.message;
      }
    });
  }


}
